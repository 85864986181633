import _external from "./external";
var exports = {};
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.z = void 0;
const z = __importStar(_external);
exports.z = z;
__exportStar(_external, exports);
exports.default = z;
export default exports;
export const __esModule = exports.__esModule,
  getErrorMap = exports.getErrorMap,
  setErrorMap = exports.setErrorMap,
  defaultErrorMap = exports.defaultErrorMap,
  isAsync = exports.isAsync,
  isValid = exports.isValid,
  isDirty = exports.isDirty,
  isAborted = exports.isAborted,
  OK = exports.OK,
  DIRTY = exports.DIRTY,
  INVALID = exports.INVALID,
  ParseStatus = exports.ParseStatus,
  addIssueToContext = exports.addIssueToContext,
  EMPTY_PATH = exports.EMPTY_PATH,
  makeIssue = exports.makeIssue,
  getParsedType = exports.getParsedType,
  ZodParsedType = exports.ZodParsedType,
  objectUtil = exports.objectUtil,
  util = exports.util,
  boolean = exports.boolean,
  bigint = exports.bigint,
  array = exports.array,
  any = exports.any,
  coerce = exports.coerce,
  ZodFirstPartyTypeKind = exports.ZodFirstPartyTypeKind,
  late = exports.late,
  ZodSchema = exports.ZodSchema,
  Schema = exports.Schema,
  custom = exports.custom,
  ZodReadonly = exports.ZodReadonly,
  ZodPipeline = exports.ZodPipeline,
  ZodBranded = exports.ZodBranded,
  BRAND = exports.BRAND,
  ZodNaN = exports.ZodNaN,
  ZodCatch = exports.ZodCatch,
  ZodDefault = exports.ZodDefault,
  ZodNullable = exports.ZodNullable,
  ZodOptional = exports.ZodOptional,
  ZodTransformer = exports.ZodTransformer,
  ZodEffects = exports.ZodEffects,
  ZodPromise = exports.ZodPromise,
  ZodNativeEnum = exports.ZodNativeEnum,
  ZodEnum = exports.ZodEnum,
  ZodLiteral = exports.ZodLiteral,
  ZodLazy = exports.ZodLazy,
  ZodFunction = exports.ZodFunction,
  ZodSet = exports.ZodSet,
  ZodMap = exports.ZodMap,
  ZodRecord = exports.ZodRecord,
  ZodTuple = exports.ZodTuple,
  ZodIntersection = exports.ZodIntersection,
  ZodDiscriminatedUnion = exports.ZodDiscriminatedUnion,
  ZodUnion = exports.ZodUnion,
  ZodObject = exports.ZodObject,
  ZodArray = exports.ZodArray,
  ZodVoid = exports.ZodVoid,
  ZodNever = exports.ZodNever,
  ZodUnknown = exports.ZodUnknown,
  ZodAny = exports.ZodAny,
  ZodNull = exports.ZodNull,
  ZodUndefined = exports.ZodUndefined,
  ZodSymbol = exports.ZodSymbol,
  ZodDate = exports.ZodDate,
  ZodBoolean = exports.ZodBoolean,
  ZodBigInt = exports.ZodBigInt,
  ZodNumber = exports.ZodNumber,
  ZodString = exports.ZodString,
  datetimeRegex = exports.datetimeRegex,
  ZodType = exports.ZodType,
  NEVER = exports.NEVER,
  unknown = exports.unknown,
  union = exports.union,
  tuple = exports.tuple,
  transformer = exports.transformer,
  symbol = exports.symbol,
  string = exports.string,
  strictObject = exports.strictObject,
  set = exports.set,
  record = exports.record,
  promise = exports.promise,
  preprocess = exports.preprocess,
  pipeline = exports.pipeline,
  ostring = exports.ostring,
  optional = exports.optional,
  onumber = exports.onumber,
  oboolean = exports.oboolean,
  object = exports.object,
  number = exports.number,
  nullable = exports.nullable,
  never = exports.never,
  nativeEnum = exports.nativeEnum,
  nan = exports.nan,
  map = exports.map,
  literal = exports.literal,
  lazy = exports.lazy,
  intersection = exports.intersection,
  effect = exports.effect,
  discriminatedUnion = exports.discriminatedUnion,
  date = exports.date,
  ZodError = exports.ZodError,
  quotelessJson = exports.quotelessJson,
  ZodIssueCode = exports.ZodIssueCode;
const _z = exports.z,
  _void = exports.void,
  _undefined = exports.undefined,
  _null = exports.null,
  _instanceof = exports.instanceof,
  _function = exports.function,
  _enum = exports.enum;
export { _z as z, _void as void, _undefined as undefined, _null as null, _instanceof as instanceof, _function as function, _enum as enum };